import {
  CimsProfile,
  Contact,
  GfdProfile,
  GpaProfile,
  RetirementPlan,
  Address,
  Beneficiary,
  Person,
  ProfessionalInfo,
  Profile,
  ProfileAssets,
  ProfileDocument,
  ProfileFund,
  ProfileQuestion
} from '@webapi/MIF.Subscription.WebApi';
import { collections } from '@constants/collections';
import { constants } from '@constants/constants';
import { BeneficiareFormModel } from '@my-beneficiaries/clause-nominative/clause-nominative.component';
import { MyDetailsFormModel } from '@my-details/my-details.component';
import { ProfileStepCode } from '@models/profile-step-code';
import { FondsRepartition } from '@webapi/MIF.Subscription.Parrot';
import { MyContactsFormModel } from '@my-details/contacts/contacts.component';
import { MyFinancialFormModel } from '@my-details/financial-situation/financial-situation.component';
import { MyBankDetailsDataModel } from '@my-details/bank-details/bank-details.component';
import { AdvertisementSourceDataModel, GpaSubscriptionModel } from '@signature/signature.extentions';

function getAuthModel(profile: Profile): AuthModel {
  if (!profile) {
    return null;
  }

  return {
    socialNumberA: profile.socialNumberA,
    contractNumber: parseInt(profile.contractNumber, 10),
    dateOfBirth: profile.person.dateOfBirth,
    expectedRetirementDate: profile.retirementPlan?.expectedRetirementDate
  } as AuthModel;
}

function getRegistrationModel(profile: Profile): RegistrationModel {
  if (!profile) {
    return null;
  }

  return {
    isMember: profile.isMember,
    civilityCode: profile.person.civilityCode?.toString(),
    lastName: profile.person.lastName,
    firstName: profile.person.firstName,
    dateOfBirth: profile.person.dateOfBirth,
    email: profile.contact.email,
    mobilePhone: profile.contact.mobilePhone,
    phone: profile.contact.phone,
    receiveEmail: profile.contact?.receiveEmail,
    receiveSms: profile.contact?.receiveSms,
    contractNumber: profile.contractNumber,
    expectedRetirementDate: profile.retirementPlan?.expectedRetirementDate
  } as RegistrationModel;
}

function getUpdatedByRegistrationModelProfileModel(model: RegistrationModel, profile: Profile, contractNumber?: number): Profile {
  if (!profile.contact) {
    profile.contact = new Contact();
  }

  if (profile.contact?.receiveSms === undefined) {
    if (model.receiveSms === undefined || model.receiveSms === '') {
      profile.contact.receiveSms = false;
    } else {
      profile.contact.receiveSms = true;
    }
  } else {
    if (model.receiveSms === '') {
      profile.contact.receiveSms = true;
    } else {
      profile.contact.receiveSms = model.receiveSms;
    }
  }

  if (profile.contact?.receiveEmail === undefined) {
    if (model.receiveEmail === undefined || model.receiveEmail === '') {
      profile.contact.receiveEmail = false;
    } else {
      profile.contact.receiveEmail = true;
    }
  } else {
    if (model.receiveEmail === '') {
      profile.contact.receiveEmail = true;
    } else {
      profile.contact.receiveEmail = model.receiveEmail;
    }
  }

  // if (contractNumber) {
  //     profile.contractNumber = contractNumber.toString();
  // }

  profile.isMember = model.isMember;

  if (!profile.isMember) {
    profile.contractNumber = null;
    profile.socialNumberA = null;
    profile.socialNumberB = null;
  }

  profile.person.civilityCode = parseInt(model.civilityCode, 10);
  profile.person.lastName = model.lastName;
  profile.person.firstName = model.firstName;
  profile.person.dateOfBirth = model.dateOfBirth;
  profile.contact.email = model.email;
  profile.contact.mobilePhone = model.mobilePhone;
  profile.contact.phone = model.phone;
  profile.profileStepCode = ProfileStepCode.ResidenceQuestions;

  if (model.expectedRetirementDate) {
    // peri
    if (!profile.retirementPlan) {
      profile.retirementPlan = new RetirementPlan({
        expectedRetirementDate: model.expectedRetirementDate,
        hasTaxDeduction: false
      });
    }
  }

  return profile;
}

function getResidenceQuestionsModel(profile: Profile): ResidenceQuestionsModel {
  if (!profile) {
    return null;
  }

  return {
    isFrenchTaxResident: profile.isFrenchTaxResident,
    isLegallyCapable: profile.isLegallyCapable,
    isUsCitizen: profile.isUsCitizen
  } as ResidenceQuestionsModel;
}

function getUpdatedByResidenceQuestionsModelProfileModel(model: ResidenceQuestionsModel, profile: Profile): Profile {
  profile.isFrenchTaxResident = model.isFrenchTaxResident;
  profile.isLegallyCapable = model.isLegallyCapable;
  profile.isUsCitizen = model.isUsCitizen;
  profile.profileStepCode = ProfileStepCode.ResidenceQuestions;

  return profile;
}

function getHealthQuestionsModel(profile: Profile, isGfd: boolean): any {
  if (!profile) {
    return null;
  }

  if (isGfd) {
    const laidOffForIllness = profile.gfdProfile?.isLaidOff !== undefined ? (profile.gfdProfile.isLaidOff === false ? constants.noAnswer : constants.yesAnswer) : undefined;
    const offWorkMore30days = profile.gfdProfile?.isOffWork !== undefined ? (profile.gfdProfile.isOffWork === false ? constants.noAnswer : constants.yesAnswer) : undefined;
    const treatmentMore30days = profile.gfdProfile?.isOnTreatment !== undefined ? (profile.gfdProfile.isOnTreatment === false ? constants.noAnswer : constants.yesAnswer) : undefined;
    const tempUnableWork = profile.gfdProfile?.isDysfunctional !== undefined ? (profile.gfdProfile.isDysfunctional === false ? constants.noAnswer : constants.yesAnswer) : undefined;
    const gfdPrivilegiersType = profile.gfdProfile?.solutionTypeCode;

    return {
      laidOffForIllness,
      offWorkMore30days,
      treatmentMore30days,
      tempUnableWork,
      gfdPrivilegiersType
    };
  } else {
    const laidOffForIllness = profile.gpaProfile?.isLaidOff !== undefined ? (profile.gpaProfile.isLaidOff === false ? constants.noAnswer : constants.yesAnswer) : undefined;
    const offWorkMore30days = profile.gpaProfile?.isOffWork !== undefined ? (profile.gpaProfile.isOffWork === false ? constants.noAnswer : constants.yesAnswer) : undefined;
    const treatmentMore30days = profile.gpaProfile?.isOnTreatment !== undefined ? (profile.gpaProfile.isOnTreatment === false ? constants.noAnswer : constants.yesAnswer) : undefined;
    const tempUnableWork = profile.gpaProfile?.isDysfunctional !== undefined ? (profile.gpaProfile.isDysfunctional === false ? constants.noAnswer : constants.yesAnswer) : undefined;

    return {
      laidOffForIllness,
      offWorkMore30days,
      treatmentMore30days,
      tempUnableWork
    };
  }
}

function getMyRateModel(profile: Profile): any {
  if (!profile) {
    return null;
  }

  const isMultipliedCoverage = profile.gpaProfile?.isMultipliedCoverage;
  const guaranteedCapitalCode = profile.gpaProfile?.guaranteedCapitalCode;
  const monthlyPayment = profile.gpaProfile?.monthlyPayment;

  return {
    isMultipliedCoverage,
    guaranteedCapitalCode,
    monthlyPayment
  };
}

function getMyGfdRateModel(profile: Profile): any {
  if (!profile) {
    return null;
  }

  const guaranteedCapitalCode = profile.gfdProfile?.guaranteedCapitalCode;
  const gfdLifetimeType = profile.gfdProfile?.contributionTypeCode;

  return {
    guaranteedCapitalCode,
    gfdLifetimeType
  };
}

function getUpdatedByHealthQuestionsModelProfileModel(model: HealthQuestionsModel, profile: Profile, isGfd: boolean): Profile {
  if (isGfd) {
    if (!profile.gfdProfile) {
      profile.gfdProfile = new GfdProfile();
    }

    profile.gfdProfile.isLaidOff = model.laidOffForIllness;
    profile.gfdProfile.isOffWork = model.offWorkMore30days;
    profile.gfdProfile.isOnTreatment = model.treatmentMore30days;
    profile.gfdProfile.isDysfunctional = model.tempUnableWork;
    profile.gfdProfile.solutionTypeCode = model.gfdPrivilegiersType;
  } else {
    if (!profile.gpaProfile) {
      profile.gpaProfile = new GpaProfile();
    }

    profile.gpaProfile.isLaidOff = model.laidOffForIllness;
    profile.gpaProfile.isOffWork = model.offWorkMore30days;
    profile.gpaProfile.isOnTreatment = model.treatmentMore30days;
    profile.gpaProfile.isDysfunctional = model.tempUnableWork;
  }

  profile.profileStepCode = ProfileStepCode.HealthQuestions;

  return profile;
}

function getUpdatedByMyRateModelProfileModel(model: MyRateModel, profile: Profile): Profile {
  if (!profile.gpaProfile) {
    profile.gpaProfile = new GpaProfile();
  }
  profile.gpaProfile.guaranteedCapitalCode = model.guaranteedCapitalCode;
  profile.gpaProfile.isMultipliedCoverage = model.isMultipliedCoverage;
  profile.gpaProfile.monthlyPayment = model.monthlyPayment;

  profile.profileStepCode = ProfileStepCode.MyRate;

  return profile;
}

function getUpdatedByMyRateGfdModelProfileModel(model: MyRateGfdModel, profile: Profile): Profile {
  if (!profile.gfdProfile) {
    profile.gfdProfile = new GfdProfile();
  }

  profile.gfdProfile.guaranteedCapitalCode = model.guaranteedCapitalCode;
  profile.gfdProfile.contributionTypeCode = model.gfdLifetimeType;

  profile.profileStepCode = ProfileStepCode.MyRate;

  return profile;
}

function getUpdatedByPaymentPlanModelProfileModel(model: PaymentPlanModel, profile: Profile): Profile {
  profile.doTransferContract = model.doTransferContract;
  profile.initialPaymentAmount = model.initialPaymentAmount;
  profile.transferContractCode = model.transferContractCode;
  profile.transferContractAmount = model.transferContractAmount;
  profile.scheduledPaymentAmount = model.scheduledPaymentAmount;
  profile.profileFunds = [];

  if (typeof model.hasTaxDeduction == 'boolean') {
    // peri
    profile.retirementPlan.hasTaxDeduction = model.hasTaxDeduction;
  }

  profile.profileStepCode = ProfileStepCode.MyProfile;

  return profile;
}

function getPaymentPlanModel(profile: Profile): PaymentPlanModel {
  if (!profile) {
    return null;
  }

  return {
    doTransferContract: profile.doTransferContract,
    initialPaymentAmount: profile.initialPaymentAmount,
    transferContractCode: profile.transferContractCode,
    scheduledPaymentAmount: profile.scheduledPaymentAmount,
    hasTaxDeduction: profile.retirementPlan?.hasTaxDeduction
  } as PaymentPlanModel;
}

function getUpdatedByProfileQuestionModelProfileModel(model: ProfileQuestion[], profile: Profile): Profile {
  profile.profileQuestions = model;
  profile.profileStepCode = ProfileStepCode.MyProfileSteps;

  return profile;
}

function getProfileQuestionsModel(profile: Profile): ProfileQuestion[] {
  if (!profile) {
    return null;
  }

  return profile.profileQuestions as ProfileQuestion[];
}

function getUpdatedByFondsRepartitionModelProfileModel(
  model: FondsRepartition[],
  profile: Profile,
  isRecommendationsFollowed: boolean,
  isGestionSousMandat: boolean,
  isGestionHorizon: boolean
): Profile {
  const ids: any[] = [];
  const profileFunds: ProfileFund[] = [];

  if (isRecommendationsFollowed || (!isRecommendationsFollowed && isGestionSousMandat) || (!isRecommendationsFollowed && isGestionHorizon)) {
    model?.forEach((item: FondsRepartition) => {
      if (item.fonds && ids.indexOf(item.fonds.id) === -1) {
        profileFunds.push(
          new ProfileFund({
            fundId: item.fonds.id,
            portion: item.repartition,
            isinCode: item.fonds?.codeISIN
          })
        );
      }
      ids.push(item.fonds?.id);
    });

    profile.profileFunds = profileFunds;
  }

  profile.profileStepCode = ProfileStepCode.MyProfileStepSummary;

  return profile;
}

function getUpdatedByDistibutedFondsModelProfileModel(model: any[], profile: Profile, isForSave: boolean): Profile {
  const ids: any[] = [];
  const profileFunds: ProfileFund[] = [];

  model.forEach((item: any, index: number, arr: any[]) => {
    if (ids.indexOf(item.controls.fond.value.id) === -1) {
      profileFunds.push(
        new ProfileFund({
          fundId: item.controls.fond.value.id,
          isinCode: item.controls.fond.value.codeISIN,
          amount: parseFloat(item.controls.brutAmount.value),
          portion: parseFloat(item.controls.fraction.value)
        })
      );
    }
    ids.push(item.controls.id.value);
  });

  profile.profileFunds = isForSave ? getPrepared(profileFunds) : profileFunds;
  profile.profileStepCode = ProfileStepCode.MyContracts;

  return profile;
}

function getPrepared(profileFunds: ProfileFund[]): ProfileFund[] {
  let portionsSummWithoutLastOne = 0;

  profileFunds.forEach((item: ProfileFund, index: number) => {
    if (index < profileFunds.length - 1) {
      portionsSummWithoutLastOne += item.portion;
    }
    if (index === profileFunds.length - 1) {
      item.portion = 100 - portionsSummWithoutLastOne;
    }
  });

  return profileFunds;
}

function getProfileFondsModel(profile: Profile): ProfileFund[] {
  if (!profile) {
    return null;
  }

  return profile.profileFunds as ProfileFund[];
}

function getUpdatedByClauseNominativeModelProfileModel(model: ClauseNominativeModel, profile: Profile, isGpa: boolean): Profile {
  const beneficiariesToSave = getBeneficiariesFromFormArray(model.beneficiaries);

  if (!isGpa) {
    profile.beneficiaries = beneficiariesToSave;
  } else {
    if (!profile.gpaProfile) {
      profile.gpaProfile = new GpaProfile();
    }

    profile.gpaProfile.beneficiaries = beneficiariesToSave;
    profile.gpaProfile.isDefaultBeneficiaryInheritance = model.isEqualParts;
  }

  profile.isDefaultBeneficiaryInheritance = model.isEqualParts;
  profile.profileStepCode = ProfileStepCode.MyBeneficiariesNominative;

  return profile;
}

function getUpdatedByPrimaryBeneficiarModelProfileModel(ben: BeneficiareFormModel, profile: Profile): Profile {
  if (!profile.cimsProfile) {
    profile.cimsProfile = new CimsProfile();
  }
  profile.cimsProfile.primaryBeneficiary = new Beneficiary({
    id: ben.id,
    person: new Person({
      civilityCode: ben.civility,
      firstName: ben.firstName,
      lastName: ben.lastName,
      dateOfBirth: new Date(ben.dob),
      nationalityCode: ben.nationality
    }),
    relationTypeCode: ben.relation,
    currentAddress: new Address({
      country: ben.country,
      city: ben.city,
      zipCode: ben.postalCode,
      addressLine: ben.adress
    }),
    placeOfBirth: new Address({
      country: ben.countryOfBirth,
      city: ben.cityOfBirth,
      zipCode: ben.postalCodeOfBirth
    })
  });

  profile.profileStepCode = ProfileStepCode.MyBeneficiaries;

  return profile;
}

function getUpdatedByGpaSubscriptionModelProfileModel(model: GpaSubscriptionModel, profile: Profile): Profile {
  if (!profile.gpaProfile) {
    profile.gpaProfile = new GpaProfile();
  }
  profile.gpaProfile.isLaidOff = model.health?.laidOffForIllness;
  profile.gpaProfile.isOffWork = model.health?.offWorkMore30days;
  profile.gpaProfile.isOnTreatment = model.health?.treatmentMore30days;
  profile.gpaProfile.isDysfunctional = model.health?.tempUnableWork;

  profile.gpaProfile.isMultipliedCoverage = model.isMultipliedCoverage;
  profile.gpaProfile.isDefaultBeneficiaryInheritance = model.isEqualParts;

  if (model.reuseBeneficiaries) {
    profile.gpaProfile.beneficiaryClauseCode = null;
    profile.gpaProfile.beneficiaries = null;
    profile.gpaProfile.beneficiaryFreeClauseText = null;
  } else {
    profile.gpaProfile.beneficiaryClauseCode = model.beneficiaryClauseCode;

    if (model.beneficiaryClauseCode === constants.beneficiaresTypes.nominative.code) {
      profile.gpaProfile.beneficiaryFreeClauseText = null;
      profile.gpaProfile.beneficiaries = getBeneficiariesFromFormArray(model.beneficiaries.getRawValue());
    }
    if (model.beneficiaryClauseCode === constants.beneficiaresTypes.libre.code) {
      profile.gpaProfile.beneficiaries = null;
      profile.gpaProfile.beneficiaryFreeClauseText = model.beneficiaryFreeClauseText;
    }
    if (model.beneficiaryClauseCode === constants.beneficiaresTypes.standard.code) {
      profile.gpaProfile.beneficiaries = null;
      profile.gpaProfile.beneficiaryFreeClauseText = null;
    }
  }

  return profile;
}

function getBeneficiariesFromFormArray(arrayOfBens: BeneficiareFormModel[]): Beneficiary[] {
  const beneficiaries: Beneficiary[] = [];

  arrayOfBens?.forEach((ben: BeneficiareFormModel) => {
    beneficiaries.push(
      new Beneficiary({
        id: ben.id,
        person: new Person({
          civilityCode: ben.civility,
          firstName: ben.firstName,
          lastName: ben.lastName,
          dateOfBirth: new Date(ben.dob),
          nationalityCode: ben.nationality
        }),
        relationTypeCode: ben.relation,
        beneficialPart: ben.allocatedShare,
        currentAddress: new Address({
          country: ben.country,
          city: ben.city,
          zipCode: ben.postalCode,
          addressLine: ben.adress
        }),
        placeOfBirth: new Address({
          country: ben.countryOfBirth,
          city: ben.cityOfBirth,
          zipCode: ben.postalCodeOfBirth
        })
      })
    );
  });

  return beneficiaries;
}

function getUpdatedByBenificiarGfdModelProfileModel(model: BinificiarGfdModel, profile: Profile): Profile {
  const ben = model.beneficiar;

  const benToSave = new Beneficiary({
    id: ben.id,
    person: new Person({
      civilityCode: ben.civility,
      firstName: ben.firstName,
      lastName: ben.lastName,
      dateOfBirth: new Date(ben.dob),
      nationalityCode: ben.nationality
    }),
    relationTypeCode: ben.relation,
    beneficialPart: ben.allocatedShare,
    currentAddress: new Address({
      country: ben.country,
      city: ben.city,
      zipCode: ben.postalCode,
      addressLine: ben.adress
    }),
    placeOfBirth: new Address({
      country: ben.countryOfBirth,
      city: ben.cityOfBirth,
      zipCode: ben.postalCodeOfBirth
    })
  });

  profile.gfdProfile.beneficiaries = [benToSave];
  profile.gfdProfile.isDefaultBeneficiaryInheritance = model.isEqualParts;

  profile.profileStepCode = ProfileStepCode.MyBeneficiaries;

  return profile;
}

function getPrimaryBeneficiaryModel(profile: Profile): BeneficiareFormModel {
  if (!profile || !profile.cimsProfile) {
    return null;
  }

  const ben = profile.cimsProfile.primaryBeneficiary;

  const beneficiairy: BeneficiareFormModel = {
    id: ben.id,
    civility: ben.person.civilityCode,
    firstName: ben.person.firstName,
    lastName: ben.person.lastName,
    relation: ben.relationTypeCode,
    dob: ben.person.dateOfBirth,
    countryOfBirth: ben.placeOfBirth.country,
    postalCodeOfBirth: ben.placeOfBirth.zipCode,
    cityOfBirth: ben.placeOfBirth.city,
    nationality: ben.person.nationalityCode,
    country: ben.currentAddress.country,
    postalCode: ben.currentAddress.zipCode,
    city: ben.currentAddress.city,
    adress: ben.currentAddress.addressLine
  };

  return beneficiairy;
}

function getClauseNominativeModel(profile: Profile, isForGpaSubscription: boolean): ClauseNominativeModel {
  if (!profile) {
    return null;
  }

  const beneficiairies: BeneficiareFormModel[] = [];
  const sourceArray = isForGpaSubscription ? profile.gpaProfile?.beneficiaries : profile.beneficiaries;

  sourceArray?.forEach((ben: Beneficiary) => {
    beneficiairies.push({
      id: ben.id,
      civility: ben.person.civilityCode,
      firstName: ben.person.firstName,
      lastName: ben.person.lastName,
      relation: ben.relationTypeCode,
      allocatedShare: ben.beneficialPart,
      dob: ben.person.dateOfBirth,
      countryOfBirth: ben.placeOfBirth.country,
      postalCodeOfBirth: ben.placeOfBirth.zipCode,
      cityOfBirth: ben.placeOfBirth.city,
      nationality: ben.person.nationalityCode,
      country: ben.currentAddress.country,
      postalCode: ben.currentAddress.zipCode,
      city: ben.currentAddress.city,
      adress: ben.currentAddress.addressLine
    } as BeneficiareFormModel);
  });

  return {
    beneficiaries: beneficiairies,
    isEqualParts: isForGpaSubscription ? profile.gpaProfile?.isDefaultBeneficiaryInheritance : profile.isDefaultBeneficiaryInheritance
  } as ClauseNominativeModel;
}

function getBinificiarGfdModel(profile: Profile): BinificiarGfdModel {
  if (!profile) {
    return null;
  }

  const beneficiairies: BeneficiareFormModel[] = [];

  profile.gfdProfile?.beneficiaries?.forEach((ben: Beneficiary) => {
    beneficiairies.push({
      id: ben.id,
      civility: ben.person.civilityCode,
      firstName: ben.person.firstName,
      lastName: ben.person.lastName,
      relation: ben.relationTypeCode,
      allocatedShare: ben.beneficialPart,
      dob: ben.person.dateOfBirth,
      countryOfBirth: ben.placeOfBirth.country,
      postalCodeOfBirth: ben.placeOfBirth.zipCode,
      cityOfBirth: ben.placeOfBirth.city,
      nationality: ben.person.nationalityCode,
      country: ben.currentAddress.country,
      postalCode: ben.currentAddress.zipCode,
      city: ben.currentAddress.city,
      adress: ben.currentAddress.addressLine
    } as BeneficiareFormModel);
  });

  return {
    beneficiar: beneficiairies[0] ?? null,
    isEqualParts: profile.gfdProfile.isDefaultBeneficiaryInheritance
  } as BinificiarGfdModel;
}

function getFirstIdentificationDocumentType(profile: Profile): ProfileDocument {
  let documentTypeCode;

  if (profile.profileDocuments?.length > 0) {
    profile.profileDocuments.forEach((doc: ProfileDocument) => {
      if (doc.typeId === collections.profileDocuments.firstIdentificationDocument.typeId) {
        documentTypeCode = doc.documentTypeCode;
      }
    });
  }

  return documentTypeCode;
}

function getMyDetailsModel(profile: Profile): MyDetailsFormModel {
  if (!profile) {
    return null;
  }

  const firstIdentificationDocumentType = getFirstIdentificationDocumentType(profile);

  const model = {
    civility: profile.person.civilityCode,
    nir: profile.nir,
    firstName: profile.person.firstName,
    commonName: profile.person.lastName,
    birthName: profile.person.birthName,
    dob: profile.person.dateOfBirth,
    countryOfBirth: profile.placeOfBirth?.country,
    postalCodeOfBirth: profile.placeOfBirth?.zipCode ? profile.placeOfBirth?.zipCode : null,
    cityOfBirth: profile.placeOfBirth?.city,
    nationality: profile.person.nationalityCode,
    familySituation: profile.maritalStatusCode,
    matrimonialRegime: profile.matrimonialRegimeCode,
    politicallyPerson: profile.politicalStatusCode !== undefined ? (profile.politicalStatusCode === constants.yesAnswerId ? constants.yesAnswer : constants.noAnswer) : null,
    politicallyPersonTypeNumber: profile.politicalStatusValue,
    firstIdentificationDocumentType: firstIdentificationDocumentType || null
  } as MyDetailsFormModel;

  return model;
}

function getUpdatedByMyDetailsModelProfileModel(model: MyDetailsFormModel, profile: Profile): Profile {
  if (!profile.placeOfBirth) {
    profile.placeOfBirth = new Address();
  }
  if (!profile.currentAddress) {
    profile.currentAddress = new Address();
  }
  if (!profile.professionalInfo) {
    profile.professionalInfo = new ProfessionalInfo();
  }
  if (!profile.profileAssets) {
    profile.profileAssets = new ProfileAssets();
  }

  profile.person.civilityCode = model.civility;
  profile.nir = model.nir;
  profile.person.firstName = model.firstName;
  profile.person.lastName = model.commonName;
  profile.person.birthName = model.birthName || profile.person.birthName;
  profile.person.dateOfBirth = new Date(model.dob);
  profile.placeOfBirth.country = model.countryOfBirth;
  profile.placeOfBirth.zipCode = model.postalCodeOfBirth;
  profile.placeOfBirth.city = model.cityOfBirth;
  profile.person.nationalityCode = model.nationality || profile.person.nationalityCode;
  profile.maritalStatusCode = model.familySituation;
  profile.matrimonialRegimeCode = model.matrimonialRegime;
  profile.matrimonialRegimeCode = model.matrimonialRegime;
  profile.politicalStatusCode = model.politicallyPerson === constants.yesAnswer ? constants.yesAnswerId : constants.noAnswerId;
  profile.politicalStatusCategoryCode = model.politicallyPersonCommonTypeNumber;
  profile.politicalStatusValue = model.politicallyPersonTypeNumber;

  profile.profileStepCode = ProfileStepCode.MyDetails;

  return profile;
}

function getUpdatedByMyContactsModelProfileModel(model: MyContactsFormModel, profile: Profile): Profile {
  if (!profile.placeOfBirth) {
    profile.placeOfBirth = new Address();
  }
  if (!profile.currentAddress) {
    profile.currentAddress = new Address();
  }
  if (!profile.professionalInfo) {
    profile.professionalInfo = new ProfessionalInfo();
  }

  profile.currentAddress.country = model.country;
  profile.currentAddress.zipCode = model.postalCode;
  profile.currentAddress.city = model.city;
  profile.currentAddress.addressLine = model.nameOfTheRoadAndPlace;
  profile.currentAddress.additionalAddress = model.additionalAddress;
  profile.currentAddress.additionalAddress2 = model.additionalAddress2;
  profile.currentAddress.locality = model.locality;
  profile.contact.mobilePhone = model.mobilePhone;
  profile.contact.phone = model.landLinePhone;
  profile.contact.email = model.email;

  profile.profileStepCode = ProfileStepCode.MyContacts;

  return profile;
}

function getMyContactsModel(profile: Profile): MyContactsFormModel {
  if (!profile) {
    return null;
  }

  const model = {
    country: profile.currentAddress?.country ? profile.currentAddress.country : null,
    postalCode: profile.currentAddress?.zipCode ? profile.currentAddress.zipCode : null,
    city: profile.currentAddress?.city ? profile.currentAddress.city : null,
    nameOfTheRoadAndPlace: profile.currentAddress?.addressLine ? profile.currentAddress.addressLine : null,
    additionalAddress: profile.currentAddress?.additionalAddress ? profile.currentAddress.additionalAddress : null,
    additionalAddress2: profile.currentAddress?.additionalAddress2 ? profile.currentAddress.additionalAddress2 : null,
    locality: profile.currentAddress?.locality ? profile.currentAddress.locality : null,
    mobilePhone: profile.contact.mobilePhone ? profile.contact.mobilePhone : null,
    landLinePhone: profile.contact.phone ? profile.contact.phone : null,
    email: profile.contact.email
  } as MyContactsFormModel;

  return model;
}

function getMyFinancialModel(profile: Profile, originOfWealthOtherId?: number, originOfContractFundsOtherId?: number): MyFinancialFormModel {
  if (!profile) {
    return null;
  }

  const areSncfRatp = profile.professionalInfo.sncfValue || profile.professionalInfo.ratpValue ? constants.yesAnswer : constants.noAnswer;
  const officerOrPensioner = areSncfRatp === constants.yesAnswer ? (profile.professionalInfo.sncfValue ? constants.sncfAswer : constants.ratpAswer) : null;

  const model = {
    areSncfRatp: areSncfRatp,
    socioProfessionalCategory: profile.professionalInfo.socialProfessionCategoryCode,
    profession: profile.professionalInfo.professionCode,
    officerOrPensioner: officerOrPensioner,
    sncfNumber: profile.professionalInfo.sncfValue,
    ratpNumber: profile.professionalInfo.ratpValue ? parseInt(profile.professionalInfo.ratpValue, 10) : undefined,
    professionalSituation: profile.professionalInfo.professionalStatusCode !== 0 ? profile.professionalInfo.professionalStatusCode : null,
    industry: profile.professionalInfo.activitySectorCode !== 0 ? profile.professionalInfo.activitySectorCode : null,
    personalNetMonthlyIncome: profile.professionalInfo.personalMonthlyNetIncomeCode,
    householdNetMonthlyIncome: profile.professionalInfo.householdMonthlyNetIncomeCode,
    originOfWealth: profile.profileAssets?.proofOfWealthAssets.map((item: number) => item.toString()),
    originOfContractFunds: profile.profileAssets?.proofOfFundsAssets.map((item: number) => item.toString()),
    proofOfWealthDescription: profile.profileAssets?.proofOfWealthDescription,
    proofOfFundsDescription: profile.profileAssets?.proofOfFundsDescription
  } as MyFinancialFormModel;

  if (originOfWealthOtherId && model.originOfWealth) {
    const index = model.originOfWealth.indexOf(originOfWealthOtherId.toString());

    if (index !== -1) {
      model.originOfWealth[index] = constants.otherAnswer;
    }
  }

  if (originOfContractFundsOtherId && model.originOfContractFunds) {
    const index = model.originOfContractFunds.indexOf(originOfContractFundsOtherId.toString());

    if (index !== -1) {
      model.originOfContractFunds[index] = constants.otherAnswer;
    }
  }

  return model;
}

function getAdvertisementSourceDataModel(profile: Profile): AdvertisementSourceDataModel {
  if (!profile) {
    return null;
  }

  const model = {
    advertisementSourceCode: profile.advertisementSource?.advertisementSourceCode,
    sponsorNumber: profile.advertisementSource?.sponsorNumber,
    memberNumber: profile.advertisementSource?.memberNumber,
    firstName: profile.advertisementSource?.firstName,
    lastName: profile.advertisementSource?.lastName,
    promoCode: profile.advertisementSource?.promoCode,
    partnerCode: profile.advertisementSource?.partnerCode,
    mifAdvisorCode: profile.advertisementSource?.mifAdvisorCode
  } as AdvertisementSourceDataModel;

  return model;
}

function getUpdatedByMyFinancialModelProfileModel(model: MyFinancialFormModel, profile: Profile): Profile {
  if (!profile.profileAssets) {
    profile.profileAssets = new ProfileAssets({
      proofOfWealthDescription: '',
      proofOfWealthAssets: [],
      proofOfFundsDescription: '',
      proofOfFundsAssets: []
    });
  }

  if (model.originOfWealthOtherId && model.originOfWealth.indexOf(constants.otherAnswer) !== -1) {
    const index = model.originOfWealth.indexOf(constants.otherAnswer);

    if (index !== -1) {
      model.originOfWealth[index] = model.originOfWealthOtherId.toString();
    }
  } else {
    model.proofOfWealthDescription = null;
  }

  if (model.originOfContractFundsOtherId && model.originOfContractFunds.indexOf(constants.otherAnswer) !== -1) {
    const index = model.originOfContractFunds.indexOf(constants.otherAnswer);

    if (index !== -1) {
      model.originOfContractFunds[index] = model.originOfContractFundsOtherId.toString();
    }
  } else {
    model.proofOfFundsDescription = null;
  }

  profile.professionalInfo.socialProfessionCategoryCode = model.socioProfessionalCategory;
  profile.professionalInfo.professionCode = model.profession;
  profile.professionalInfo.sncfValue = model.sncfNumber;
  profile.professionalInfo.ratpValue = model.ratpNumber ? model.ratpNumber.toString() : null;
  profile.professionalInfo.professionalStatusCode = model.professionalSituation;
  profile.professionalInfo.activitySectorCode = model.industry;
  profile.professionalInfo.personalMonthlyNetIncomeCode = model.personalNetMonthlyIncome;
  profile.professionalInfo.householdMonthlyNetIncomeCode = model.householdNetMonthlyIncome;
  profile.profileAssets.proofOfWealthAssets = model.originOfWealth.map((item: string) => parseInt(item), 10);
  profile.profileAssets.proofOfFundsAssets = model.originOfContractFunds.map((item: string) => parseInt(item), 10);
  profile.profileAssets.proofOfWealthDescription = model.proofOfWealthDescription;
  profile.profileAssets.proofOfFundsDescription = model.proofOfFundsDescription;

  profile.profileStepCode = ProfileStepCode.MyFinancial;

  return profile;
}

function getUpdatedByMyBankDetailsDataModelProfileModel(model: MyBankDetailsDataModel, profile: Profile): Profile {
  profile.iban = model.iban.allToUpperCase();
  profile.bic = model.bic;
  profile.rum = model.rum;
  profile.useExistingRum = model.useExistingRum;

  profile.profileStepCode = ProfileStepCode.MyBankDetails;

  return profile;
}

export {
  getAuthModel,
  getRegistrationModel,
  getUpdatedByRegistrationModelProfileModel,
  getResidenceQuestionsModel,
  getUpdatedByResidenceQuestionsModelProfileModel,
  getHealthQuestionsModel,
  getUpdatedByHealthQuestionsModelProfileModel,
  getPaymentPlanModel,
  getUpdatedByPaymentPlanModelProfileModel,
  getProfileQuestionsModel,
  getUpdatedByProfileQuestionModelProfileModel,
  getProfileFondsModel,
  getUpdatedByFondsRepartitionModelProfileModel,
  getClauseNominativeModel,
  getUpdatedByClauseNominativeModelProfileModel,
  getMyDetailsModel,
  getUpdatedByMyDetailsModelProfileModel,
  getMyContactsModel,
  getUpdatedByMyContactsModelProfileModel,
  getMyFinancialModel,
  getUpdatedByMyFinancialModelProfileModel,
  getUpdatedByMyBankDetailsDataModelProfileModel,
  getUpdatedByDistibutedFondsModelProfileModel,
  getMyRateModel,
  getMyGfdRateModel,
  getBinificiarGfdModel,
  getUpdatedByMyRateModelProfileModel,
  getUpdatedByMyRateGfdModelProfileModel,
  getUpdatedByBenificiarGfdModelProfileModel,
  getUpdatedByGpaSubscriptionModelProfileModel,
  getAdvertisementSourceDataModel,
  getUpdatedByPrimaryBeneficiarModelProfileModel,
  getPrimaryBeneficiaryModel
};

export class AuthModel {
  socialNumberA: string;
  contractNumber: number;
  dateOfBirth: Date;
  expectedRetirementDate?: Date; // peri
}

export class RegistrationModel {
  civilityCode: string;
  lastName: string;
  firstName: string;
  dateOfBirth: Date;
  email: string;
  mobilePhone: string;
  phone: string;
  receiveEmail: any;
  receiveSms: any;
  isMember?: boolean;
  contractNumber?: string;
  expectedRetirementDate?: Date; // peri
}

export class ResidenceQuestionsModel {
  isFrenchTaxResident: boolean;
  isLegallyCapable: boolean;
  isUsCitizen: boolean;
}

export class HealthQuestionsModel {
  laidOffForIllness: boolean;
  tempUnableWork: boolean;
  offWorkMore30days: boolean;
  treatmentMore30days: boolean;
  gfdPrivilegiersType?: number;
}

export class MyRateModel {
  guaranteedCapitalCode?: number;
  isMultipliedCoverage?: boolean;
  monthlyPayment?: number;
}

export class MyRateGfdModel {
  guaranteedCapitalCode?: number;
  gfdLifetimeType?: number;
}

export class PaymentPlanModel {
  doTransferContract?: boolean;
  transferContractCode?: number;
  transferContractAmount?: number;
  initialPaymentAmount?: number;
  scheduledPaymentAmount?: number;
  hasTaxDeduction?: boolean; // peri
}

export class ClauseNominativeModel {
  beneficiaries?: BeneficiareFormModel[];
  isEqualParts?: boolean;
}

export class BinificiarGfdModel {
  beneficiar?: BeneficiareFormModel;
  isEqualParts?: boolean;
}
